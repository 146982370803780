import { CREATEACTAS, CREATECLIENT, CREATEENTRY, CREATEITEM, CREATEMAG, CREATEOT, CREATEROLE, CREATEUSER, TECNICHAL, VIEWCALENDAR, VIEWCLIENT, VIEWENTRY, VIEWOT, VIEWROLE } from "../components/Roles/ConstantRoute";
import { useAuth } from "../hooks/useAuth"; 
import React from 'react'

export const  RutasCrear=(permisos)=> {
    let Rutas=[]
    let {userData}  = useAuth();
    //console.log(permisos)
    
        if(permisos.indexOf(CREATEOT) !== -1)
        Rutas.push(        
            {
            name:'Orden de Trabajo',
            route:'/crearOt',
           })
        if(permisos.indexOf(CREATEUSER) !== -1)
        Rutas.push(        
            {
            name:'Usuario',
            route:'/users',
            })
        if(permisos.indexOf(CREATECLIENT) !== -1)
        Rutas.push(        
            {
            name:'Cliente',
            route:'/clientes',
            })
        if(permisos.indexOf(CREATEENTRY) !== -1)
        Rutas.push(        
            {
            name:'Ingreso Equipos',
            route:'Ingresos/crear',
            })

        if(permisos.indexOf(CREATEITEM) !== -1)
        Rutas.push(        
            {
            name:'Items',
            route:'/items',
            })
        if(permisos.indexOf(CREATEMAG) !== -1)
        Rutas.push(        
            {
            name:'Magnitud',
            route:'/magnitud',
            })
        if(permisos.indexOf(CREATEROLE) !== -1)
        Rutas.push(        
            {
            name:'Roles',
            route:'/Roles',
            })

  //console.log(Rutas)

  return (Rutas)
}

export const  RutasSideBar=(permisos)=> {
    let Rutas=[]
    let {userData}  = useAuth();
    //console.log(permisos)
    
        if(permisos.indexOf(VIEWCALENDAR) !== -1)
        Rutas.push(        
            {
                name:'Calendario',
                icon:'bi-calendar3',
                route:'/calendario',
            })

        if(permisos.indexOf(VIEWOT) !== -1)
        Rutas.push(        
            {
                name:'Orden de Trabajo',
                icon:'bi-list-task',
                route:'/otlista/total',
            })
        if(permisos.indexOf(VIEWENTRY) !== -1)
        Rutas.push(        
            {
                name:'Ingresos Equipos',
                icon:'bi bi-building-up',
                route:'/Ingresos/listar',
            })
        if(permisos.indexOf(VIEWCLIENT) !== -1)
        Rutas.push(        
            {
                name:'Clientes',
                icon:'bi bi-people',
                route:'/clientes',
            })

        if(permisos.indexOf(VIEWROLE) !== -1)
        Rutas.push(        
            {
                name:'Roles',
                icon:'bi bi-person-rolodex',
                route:'/Roles',
            })
        if(permisos.indexOf(TECNICHAL) !== -1)
        Rutas.push(        
            {
                name:'Gestión Técnica',
                icon:'bi bi-gear-wide-connected',
                subRoute:[
                    {   
                        name: 'Notificaciones',
                        route:'/NotficacionTecnica',
                    },
                    {   
                        name: 'Inventario',
                        route:'/Inventario',
                    },
                    {
                        name: 'Actividades',
                        route:'/Actividades',
                    }

                ] 
            })


  console.log(Rutas)

  return (Rutas)
}