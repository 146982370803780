import React, { useEffect, useState } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { actualizarDatos, getDataList } from '../../api/common'
import { Dropdown, DropdownToggle } from 'react-bootstrap'
import logoIngreso from "../../img/Ingreso_2.jpg";
import FinalLogo from "../../img/finalizacion.jpg";
import logoHT from "../../img/HT_2.jpg";
import logoOT from "../../img/HT_1.jpg";
import vencimientoAct from "../../img/Vencimiento_Actividad.jpg";
import logoAP from "../../img/HT.jpg";
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { initWebsockets } from '../../libs/http/initWebsocket';
import { Alertassw } from '../Alertas/Alertassw';
import {Howl} from 'howler'
import sonido from '../../sound/SoundNot.mp3' 


const socket = initWebsockets();
moment.locale("es");


export  const ConsultNot=()=> {
    const navigate = useNavigate();
    const {userData} = useAuth()
    const [notificaciones, setNotificaciones]=useState()
    const  [refresh, setRefresh]=useState(false)  

    const  openNot= async(data)=>{
        try {

            const notVista= await actualizarDatos ('Notificacion',
                { $addToSet: { leidaPor: {
                    usuarioId:userData._id,
                    fechaLeida:new Date()
                } } 
            },data._id)
            setRefresh(!refresh)
            navigate(data.url);
            
        } catch (error) {
            
        }
    }

    const funciones = {
        "Calibraciones vencidas": FinalLogo,
        "Ingreso de Items":logoIngreso,
        "Aprobación de Ítems":logoAP,
        "Finalización de Ítems":logoHT,
        "Creación OT": logoOT,
        "Actividades": vencimientoAct
      };

    useEffect(()=>{       
        async function consultarNot (){
            const resul = await getDataList('Notificacion',userData)
            console.log(resul)
            setNotificaciones(resul.data.data)
        }
        
        consultarNot();
    },[refresh])

    useEffect(()=>{
        socket.on('nuevaNotificacion', (data) => {
            console.log('Nueva notificación recibida en el cliente:', data);

            const usuarioPresente = data.users.some(usuario => usuario._id=== userData._id);
            const rolPresente = data.roles.some(rol => rol._id === userData.role._id);
            console.log("Usuario Presente : ",usuarioPresente, " : rolpresente: ",rolPresente);

           if(usuarioPresente || rolPresente ){
            
            Alertassw('toast', 'nueva notificacion recibida')
            const sound = new Howl({
                src:[sonido]
            })
            sound.play();
            setRefresh(!refresh)
        }
            // Actualiza el estado o realiza acciones según la notificación recibida
          });

          return()=>{
            socket.off("nuevaNotificacion")
          };

    })
  return (
    <div>
                       <Dropdown Dropdown as="li">
                            <DropdownToggle className="icono-link i-false" as="a">
                                <i className="bi bi-bell " width="19.375" height="24" viewBox="0 0 19.375 24"></i>
                                {notificaciones?.length>0? <span className="badge badge-danger rounded-circle" >{notificaciones?.length}</span>:null}
                            </DropdownToggle >
                            <Dropdown.Menu align="right" className="perfectScrollbar">

                            {!notificaciones || notificaciones?.length===0? 
                            <Dropdown.Item>
                                <h6 className="tituloNot">No tienes notificaciones pendientes</h6>
                            </Dropdown.Item>
                            :null}
                            {notificaciones?.slice().reverse().map((notif, index) => (
                                <Dropdown.Item onClick={()=>openNot(notif)} key={index} >
                                <div className="timeline-panel">
                                    <div className>
                                        {notif.url==="Actividades"? <img alt="images" width={40} src={funciones[notif?.url]}/>
                                        :
                                        <img alt="images" width={40} src={funciones[notif?.titulo]}/>}
                                    </div>
                                    <div className="not-body">
                                        <h6 className="tituloNot">{notif?.titulo}</h6>
                                        <p className="mensageNot">{notif?.mensage}</p>
                                        <p className="dateNot">{moment(notif?.createdAt).format("L")}</p>
                                    </div>
                                </div>
                            </Dropdown.Item>
                            ))}
                            </Dropdown.Menu>
                        </Dropdown>
      
    </div>
  )
}

