import React, { useState } from 'react';
import { saveFile } from '../../helpers/firebase'
import { Modal, Button } from 'react-bootstrap';

const FileUploadModal = ({ show, onClose, onFileSelected }) => {
  const handleFileChange = async(e) => {
    const file = e.target.files[0];
    if (file) {
        try{
            const link = await saveFile(e.target,'documents')
            console.log(link)
            onFileSelected(link)
            onClose(); // Cerramos el modal
        }catch(error){
            console.log(error)
        }
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Subir Archivo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type="file" accept=".pdf,.doc,.docx" onChange={handleFileChange} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancelar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default FileUploadModal;
