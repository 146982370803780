import React, { useEffect, useRef, useState } from 'react'
import { actualizarDatos, crearDatos } from '../../api/common'
import { Mediciones } from './Mediciones'
import { PeriodoPame } from './PeriodoPame'
import { Alertassw } from '../Alertas/Alertassw'
import { PameEqPatron } from './PameEqPatron'
import { Modales } from '../Modales'
import moment from 'moment';
import { deleteFile, saveFile } from '../../helpers/firebase'
import { Modalcarga } from '../Modales/Modalcarga'
import Spinner from '../commons/Spinner'


const Form_2EqPatron = ({dataActividad, setDataActividad, setEstadoModal, setTitulo, patronData, accion, setRefresh, refresh, setdetalle}) => {
  const [modalPAME, setModalPAME]=useState(false)
  const [dataActualizar, setDataActualizar]=useState({})
  console.log(dataActualizar);
  const [inputs, setInputs] = useState([]);
  const [pame, setPame]=useState();
  const [estadoCheck, setEstadoCheck]=useState(false)
  const [estadomodalIn, setEstadomodalIn]=useState(false)
  const [fileName, setFileName] = useState('');
  const fileInputRef = useRef(null);
  console.log(dataActividad)

  const handleSubmit=async(e)=>{
    e.preventDefault();
    dataActividad.equipo=patronData?._id
    const selecType = document.getElementById('selectOpciones');
    const selecAccion = document.getElementById('selectOpciones_2');
    try {
      if(!dataActividad.tipoActividad) {
        selecType.classList.add('invalidSeleccion');
        setTimeout(() => { selecType.classList.remove('invalidSeleccion'); }, 1500);
        throw new Error('Tipo de actividadad no completado.');
      }
      if(!dataActividad.accionVencer){
        selecAccion.classList.add('invalidSeleccion');
        setTimeout(() => { selecAccion.classList.remove('invalidSeleccion'); }, 1500);
        throw new Error('Tipo de actividadad no completado.');
      }
      if(dataActividad.tipoActividad==='Calibración'){
        let copiaInputs = handleCheckInputs()
        dataActividad.mediciones=copiaInputs
        if(accion===2){dataActualizar.mediciones=copiaInputs}
      }
       const reusltado= accion!==2? await crearDatos('actividad',dataActividad):await actualizarDatos('actividad',dataActualizar,dataActividad._id);
      if(reusltado.status===200){
        Alertassw('success','Actividad Creada exitosamente')
        setEstadoModal(false);
        setRefresh(!refresh);
        if(accion!==1){
          setdetalle(false)
        
        }
      }
    } catch (error) {
    }

  }


  const handleselect=(e)=>{
    console.log(e.target.value)
    if(e.target.value!='NA'){
      setDataActualizar({
        ...dataActualizar,
        tipoActividad:e.target.value
      })
      setDataActividad({
        ...dataActividad,
        tipoActividad:e.target.value
    })
    }else{
      setDataActualizar({
        ...dataActualizar,
        tipoActividad:null
      })
      setDataActividad({
        ...dataActividad,
        tipoActividad:null
    })
    }
  }
  const handleselect_2=(e)=>{
    const {name,value}= e.target
    if(e.target.value!='NA'){
      setDataActualizar({
        ...dataActualizar,
        [name]:value
      })
      setDataActividad({
        ...dataActividad,
        [name]:value
    })
    }else{
      setDataActualizar({
        ...dataActualizar,
        [name]:null
      })
      setDataActividad({
        ...dataActividad,
        [name]:null
    })
    }
  }
  const handleChecked=(e)=>{
    console.log(e.target.checked)
    setEstadoCheck(e.target.checked)
    if(e.target.checked){
      setDataActualizar({
        ...dataActualizar,
        estado:'Cerrado'
      })
      setDataActividad({
        ...dataActividad,
        estado:'Cerrado'
    })
    }else{
      setDataActualizar({
        ...dataActualizar,
        estado:'Abierta'
      })
      setDataActividad({
        ...dataActividad,
        estado:'Abierta'
    })
    }
  }
  const handledata=(event)=>{
    try {
      if(event.target.name==='FechaInicio'){
        const fechaSeleccionada = event.target.value; // Obtener la fecha del input
        const [anio, mes, dia] = fechaSeleccionada.split('-').map(Number); // Separar año, mes y día
        const fechaUTC = new Date(Date.UTC(anio, mes - 1, dia,12)); // Crear una fecha UTC
        // Utilizar fechaUTC para cualquier procesamiento posterior
        setDataActividad({
          ...dataActividad,
          [event.target.name]: fechaUTC
      })
        setDataActualizar({
          ...dataActualizar,
          [event.target.name]: fechaUTC
        })
      }
      else{
        console.log(event.target.value)
        setDataActualizar({
          ...dataActualizar,
          [event.target.name]: event.target.value
        })
        setDataActividad({
          ...dataActividad,
          [event.target.name]:event.target.value
      })
      }

    } catch (error) {
      Alertassw('error','Mediciones incompletas', 'Verifica cada campo o completa minimo 3 mediciones')
    }

  }
  const handleCheckInputs = () => {

    const mediciones = document.getElementById('mediciones');
    const fecha = document.getElementById('FechaIni');
    const textalert= document.getElementById('alertaTexid')
    
  if(dataActividad.FechaInicio ==='' || !dataActividad?.FechaInicio){
    fecha.classList.add('invalidSeleccion');
    setTimeout(() => { fecha.classList.remove('invalidSeleccion'); }, 1500);
    throw new Error('Debes Ingresar la fecha de la actividad antes de continuar');
  }
  
  let newInputs=[...inputs]

  newInputs.forEach(medicion => {
    for (let key in medicion) {
        if (!isNaN(parseFloat(medicion[key]))) {
            key==='_id'? medicion[key] = (medicion[key]): medicion[key] = parseFloat(medicion[key]);  
        }
    }
  });
    const isLastRowEmpty = newInputs[newInputs.length - 1] && Object.values(newInputs[newInputs.length - 1]).every(value => value === '');
    
    if (isLastRowEmpty) {
      
        newInputs.pop();
    }


    if(newInputs.length<3){
      textalert.classList.replace('NoalertaTex','alertaTex')
      mediciones.classList.add('invalidSeleccion');
      setTimeout(() => { 
        mediciones.classList.remove('invalidSeleccion');
        textalert.classList.replace('alertaTex','NoalertaTex');
      }, 1500);
      throw new Error('Se requieren mínimo 3 mediciones para continuar.');
    }

    const hasEmptyFields = newInputs.some(input =>
        Object.values(input).some(value => value === '')
    );

    if (hasEmptyFields) {
        textalert.classList.replace('NoalertaTex','alertaTex')
        mediciones.classList.add('invalidSeleccion');
        setTimeout(() => { 
          mediciones.classList.remove('invalidSeleccion');
          textalert.classList.replace('alertaTex','NoalertaTex');
        }, 1500);
      throw new Error('Completa todos los campos antes de continuar.');
    }
    return(newInputs)
  };
  const consultarPame=()=>{
    try {
      
      let newInputs=handleCheckInputs()
      dataActividad.mediciones=newInputs
      console.log('Patron data: ',patronData)
      let Pameconsultado=PeriodoPame(patronData,dataActividad,setPame);
    } catch (error) {
    // Alertassw('error','Información Incompleta', error.message)
    }
  }
  const verPame=()=>{
    setModalPAME(true);
  }

  const onUpload = async( event )=>{
    if(event.target?.files[0].type==='application/pdf'){
      try{
        setEstadomodalIn(true)
        const link = await saveFile(event.target,'documents')
        if(link){
          Alertassw('charge','Archivo caragdo correctamente')
          console.log('Link de Archivo', link)
          setFileName(event.target.files[0].name);
          setDataActividad({
            ...dataActividad,
            evidencia:link
          })
          if(accion==2)
          setDataActualizar({
            ...dataActualizar,
            evidencia:link
          })
          setEstadomodalIn(false)
          if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        }
      }catch(error){
        setEstadomodalIn(false)
        console.log(error)
      }
    }else{
        Alertassw('error','No se ha subido un archivo PDF.','Por favor, inténtelo de nuevo.')
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
      }
    }
   } 

   const clearFileInput=async()=>{
    try {
      setEstadomodalIn(true)
      const eliminado = await deleteFile(dataActividad.evidencia)
      console.log(eliminado)
      if(!eliminado){
        Alertassw('charge','Archivo Eliminado correctamente')
        setDataActividad({
          ...dataActividad,
          evidencia:null
        })
        setFileName(null);
       setEstadomodalIn(false)

      }
    } catch (error) {
      setEstadomodalIn(false)
      console.log(error)
    }

   }

  useEffect (()=>{
      switch (accion)
      {
        case 1:
          setInputs([
            { valorNominal: '', valorMedido: '', error: '', exactitudTolerancia: '', incertidumbreExpandida: '',correccionIndicacion:'', cumpleExactitud: '' }
          ])
        break
        case 2:
          if(dataActividad?.mediciones.length===0){
            setInputs([
              { valorNominal: '', valorMedido: '', error: '', exactitudTolerancia: '', incertidumbreExpandida: '',correccionIndicacion:'', cumpleExactitud: '' }
            ])
          }else{
            setInputs(dataActividad?.mediciones)
          }
        break
        case 3:
          if(dataActividad?.mediciones.length===0){
            setInputs([
              { valorNominal: '', valorMedido: '', error: '', exactitudTolerancia: '', incertidumbreExpandida: '',correccionIndicacion:'', cumpleExactitud: '' }
            ])
          }else{
          const inputsTemplete=dataActividad?.mediciones.map((medicion)=>({ 
            valorNominal: medicion.valorNominal, 
            valorMedido: '', 
            error: '', 
            exactitudTolerancia: '', 
            incertidumbreExpandida: '',
            correccionIndicacion:'', 
            cumpleExactitud: '' 
          }))
          setInputs(inputsTemplete)
        }
          console.log('accion: ',3)
        break
      }
     
  },[])
  return (
    <div>
      <div>
        <form  className='form_container'  onSubmit={handleSubmit}>
        <div className='form_group '>
            <label htmlFor="selectOpciones" className='form_group_label' >Tipo de actividad:</label>
            <select id="selectOpciones" name='tipo' className="form-select " required onChange={handleselect} value={dataActividad?.tipoActividad} disabled={accion===3?true:false}>
                <option value={"NA"}>Selecciona una opción...</option>
                <option value="Mantenimiento Preventivo">Mantenimiento Preventivo</option>
                <option value="Mantenimiento Correctivo">Mantenimiento Correctivo</option>
                <option value="Calibración">Calibración</option>
                <option value="Comprobación Intermedia">Comprobación Intermedia</option>
            </select>
        </div>
        <div className='form_group '>
                <label htmlFor={'fecha'} className='form_group_label'>Fecha Actividad</label>
                <input id='FechaIni' name={'FechaInicio'} className='titulo w-100' type={'date' } onChange={handledata} value={dataActividad.FechaInicio? moment(dataActividad.FechaInicio).format('YYYY-MM-DD'):null} required/>
        </div>
        <div className='form_group d-flex justify-content-start '>
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={dataActividad.estado==='Cerrado'?true:false} onClick={handleChecked}/>
          <label class="form-check-label" for="flexCheckDefault">
            Única actividad
          </label>
          </div>
        </div>
        {dataActividad?.tipoActividad === "Calibración" && (
          <>
        <div className='form_group '>
          <label htmlFor={'magnitud'} className='form_group_label'>Magnitud</label>
          <input id='magnitud' name={'magnitud'} className='titulo w-100'  onChange={handledata} value={dataActividad?.magnitud} required/>
        </div>
        <div className='form_group ' id='mediciones'>
        <label htmlFor={'perMtto'} className='form_group_label'>Resultado Mediciones</label>
        <Mediciones inputs={inputs} setInputs={setInputs}/>
        </div>
        <p id='alertaTexid' className='NoalertaTex'>Se requieren mínimo 3 mediciones y todos los campos diligenciados</p>
        </>)
        } 
        <div className='form_group '>
                <div className="container mg-0 px-0">
                  <div className="row">
                    <div className="col-md-6">
                      <label htmlFor={'perMtto'} className='form_group_label'>Periodo de la actividad</label>
                    </div>
                    <div className="col-md-3">
                      {pame?<a className='text-decoration-none cursor-p' onClick={verPame}>Ver PAME</a>:<></>}
                    </div>
                    <div className="col-md-3 d-flex justify-content-end">
                     {dataActividad.tipoActividad==='Calibración'?<a className='text-decoration-none cursor-p' onClick={()=>consultarPame()}>Consultar</a>:<></>}
                    </div>
                  </div>
                </div>
                <input name='frecuencia' className='titulo w-100' type={'number'}  onChange={handledata} value={dataActividad?.frecuencia} required={estadoCheck? false: true}/>
            </div>
            <div className='form_group '>
            <label htmlFor="selectOpciones_2" className='form_group_label' >Acción al vencer:</label>
            <select id="selectOpciones_2" name='tipo' className="form-select" required onChange={handleselect_2} value={dataActividad?.accionVencer}>
                <option value="" disabled>Selecciona una opción...</option>
                <option value="Alertar">Alertar</option>
                <option value="Inactivar">Inactivar y Alertar</option>
            </select>
        </div>
        <div className='form_group '>
            <label htmlFor="selectOpciones_2" className='form_group_label' >Estado :</label>
            <select id="selectOpciones_2" name='estadoOperativo' className="form-select" required onChange={handleselect_2} value={dataActividad?.estadoOperativo||""}>
                <option value="" disabled>Selecciona una opción...</option>
                <option value="Activo">Activo</option>
                <option value="Inactivo">Inactivo</option>
            </select>
        </div>
            <div className='form_group ' >
                <label htmlFor={'evidencia'} className='form_group_label'>Consecutivo</label>
                <input name={'consecutivo'} className='titulo w-100' onChange={handledata} value={dataActividad?.consecutivo} required/>
            </div>
            <div className='form_group ' >
                <label htmlFor={'evidencia'} className='form_group_label'>Evidencia</label>
                <div className='form_group_label'>
                {fileName && (
                    <div className='file_info'>
                        <span className='file_name'>{fileName}</span>
                        <div  onClick={clearFileInput}className="bi bi-trash cursor-p font_sz delete_icon"/>
                    </div>
                )}
                </div>
                <input  type="file"  name={'Evidencia'} className='titulo w-100' onChange={onUpload} ref={fileInputRef}/>
            </div>
            <div className='form_group ' >
                <label htmlFor={'responsable'} className='form_group_label'>Responsable de la Actividad</label>
                <input name={'responsable'} className='titulo w-100' onChange={handledata} value={dataActividad?.responsable} required/>
            </div>
            <div className='form_group ' >
                <label htmlFor={'observacion'} className='form_group_label'>Observación (Opcional)</label>
                <textarea name={'observacion'} className='titulo w-100' onChange={handledata} value={dataActividad?.observacion}/>
            </div>
            <div className='w-100'>
              <button type="submit" className='btn-btn-primary'>{accion===1?'Agregar actividad': accion===2? 'Editar Actividad':'Cargar Actividad'}</button>
            </div>
        </form>
    </div>
    <Modales
        estado={modalPAME}
        cambiarestado={setModalPAME}
        titulo={'PAME'}
    >
          <PameEqPatron pame={pame}/>
    </Modales >
    <Modalcarga
      estadoIn={estadomodalIn} cambiarestadoIn={setEstadomodalIn}
    >
      <Spinner/>
    </Modalcarga>

      
    </div>
  )
}

export default Form_2EqPatron
